.chart-container{
    width: 100%;
    height: auto;
    padding-top: 3%;
}
.tags-chart{
    z-index:999;
}
.tags-chart-values{
    position: absolute;
    top: 7%;
    left: 7%;
    z-index: 1;
}