
html, body {
    height: 100%;
    margin: 0;
    padding: 0;
}

div img {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width:150px;
    height:150px;
}
