.has-error {
  .form-control,
  button[class^="css-"],
  .user-selectbox > div,
  .ck.ck-editor__main > .ck-editor__editable,
  [class*="-control"],
  .ck.ck-toolbar {
    border-color: red;
  }
}

.help-block {
  color: red;
  font-size: 13px;
}
