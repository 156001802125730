@import url('https://fonts.googleapis.com/css2?family=Barlow&family=Barlow+Condensed&family=Barlow+Semi+Condensed&family=Oswald&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro:wght@500&display=swap');
html {
  height: 100%;
}
body {
  height: 100%;
  overflow-x: hidden;
}
#root {
  height: 100%;
  background-color: white;
}
a {
  color: #0366d6;
}
.pSemiCondensed{
  font-family: 'Barlow Semi Condensed', sans-serif;
}
.pText{
  font-family: 'Barlow Light', sans-serif;
}
.headingText{
  font-family: 'Barlow Black', sans-serif;
}
.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}
.btn-whistle{
  margin-top: 15px;
  background-color: #5695B2;
  color: #fff;
}
.btn-send{
  float: right
}

.chart-card{
  height: 300px;
  max-height: 300px;
}
.guide-section{
  margin-top: -30px;
  
}
.guide-card{
  //height: 300px;
  -webkit-box-shadow:  25px 0px 25px -25px rgba(148,148,148,0.75),  -25px 0px 25px -25px rgba(0, 0, 0, 0.45);
  -moz-box-shadow:  25px 0px 25px -25px rgba(0, 0, 0, 0.45),  -25px 0px 25px -25px rgba(0, 0, 0, 0.45);
  box-shadow:  25px 0px 25px -25px rgba(0, 0, 0, 0.45),  -25px 0px 25px -25px rgba(0, 0, 0, 0.45);
}
// .guide-card-btn{
//   position: absolute;
//   top: 15;
// }
.chart-row{
  min-height: 25%;
  background: rgb(69,203,233);
  background: linear-gradient(90deg, rgba(69,203,233,1) 0%, rgba(110,139,228,1) 73%, rgba(133,104,226,1) 100%);
  &.partner{
    background: rgb(110,218,63);
    background: linear-gradient(90deg, rgba(110,218,63,1) 0%, rgba(48,178,16,1) 73%, rgba(44,143,27,1) 100%);
  }
  &.users{
    background: rgb(255,127,78);
    background: linear-gradient(90deg, rgba(245,137,68,1) 0%, rgba(238,90,38,1) 73%, rgba(255,99,49,1) 100%);
  }
  &.archived{
    background: rgb(204,129,242);
    background: linear-gradient(90deg, rgba(174,101,210,1) 0%, rgba(159,87,195,1) 73%, rgba(133,51,174,1) 100%);
  }
  &.dashboard{
    min-height: 45%;
  }
}
.cases-row{
  background-color: white;
  z-index: 1;
}
.page-title{
  color: white
}
.cases-table{
  height: auto;
  margin-top: -30px;
  margin-bottom: 30px;
  border-radius: 5px;
  background-color: white;
  overflow-y: scroll;
  -webkit-box-shadow:  25px 0px 25px -25px rgba(148,148,148,0.75),  -25px 0px 25px -25px rgba(0, 0, 0, 0.45);
  -moz-box-shadow:  25px 0px 25px -25px rgba(0, 0, 0, 0.45),  -25px 0px 25px -25px rgba(0, 0, 0, 0.45);
  box-shadow:  25px 0px 25px -25px rgba(0, 0, 0, 0.45),  -25px 0px 25px -25px rgba(0, 0, 0, 0.45);
}
.cases-table tbody tr:hover{
  background-color:rgba(194, 194, 194, 0.50);
  cursor: pointer;
}
.cases-table-id{
  font-family: 'Source Code Pro', monospace;
}
.listAllTags{
  position: absolute;
  z-index: 999;
  background-color: #17a2b8;
  opacity: 0.8;
  min-width: 90px;
  border-radius: 5px;
}

.dropdown-menu {
  max-height: 80vh;
}

#logList{
  background-color: #f8f9fa;
  box-shadow:         inset 15px 17px 10px -20px rgba(128,128,128,1);
  -webkit-box-shadow: inset 15px 17px 10px -20px rgba(128,128,128,1);
  -moz-box-shadow:    inset 15px 17px 10px -20px rgba(128,128,128,1);
  margin-top: -10px;
  min-height: 100vh;

}
.Log{
  margin-top: 20px;
}
.LegalTech-noteBorder{
  border-left: 5px solid #fff3cd;
}
.loading {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}
@media only screen and (max-width: 990px) {
  .tags-chart-container{
    display:none;
  }
  
}
@media only screen and (max-height: 900px) {
  .chart-card{
    height: 200px;
    max-height: 200px;
  }
  .tags-chart-values{
    line-height: 0.9;
    font-size: 12px;
  }
}
@media only screen and (max-height: 600px) {
  .chart-card{
    display: none;
  }
  
}
@media only screen and (max-height: 660px) {
  .cases-row{
    margin-top:50px;
  }
}
@media screen and (min-height: 900px) and (max-width: 1350px) {
  .tags-chart-values{
    line-height: 1.3;
    font-size: 12px;
  }
}
@media screen and (min-height: 900px) and (max-width: 1350px) {
  .tags-chart-values{
    line-height: 1.3;
    font-size: 12px;
  }
}