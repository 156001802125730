html,
body {
    /* overflow: hidden; */
    -webkit-overflow-scrolling: touch;
}

a.navbar-brand {
    white-space: normal;
    text-align: center;
    word-break: break-all;
}

html {
    font-size: 14px;
}

#left-navigation {
    float: left;
    background-color: #fff;
    height: 100%;
    box-shadow: -3px 0px 11px 1px rgba(148, 148, 148, 0.75) inset;
    -webkit-box-shadow: -3px 0px 11px 1px rgba(148, 148, 148, 0.75) inset;
    -moz-box-shadow: -3px 0px 11px 1px rgba(148, 148, 148, 0.75) inset;
    position: fixed;
    z-index: 99;
    padding-left: 0;
    transition: 0.5s;
}

#nav-top-segment {
    margin-top: 20px;
    text-align: center;
}

.company-name {
    color: #495057;
    text-decoration: none;
    font-size: 20px;
}

.company-name:hover {
    text-decoration: none;
    color: #495057;
}

.company-tin {
    font-size: 12px;
}

.side-nav-link {
    text-decoration: none;
    color: #495057;
    font-size: 16px;
}

.side-nav-link:hover {
    text-decoration: none;
    color: #495057;
    font-size: 16px;
    background-color: #D3d3d3;
}

.side-nav-link-active {
    color: red;
}

.side-nav-item {
    text-decoration: none;
    color: #495057;
}

.bottom-nav {
    position: fixed;
    height: auto;
    min-height: 100px;
    bottom: 0;
    /* overflow-x:hidden; */
    padding-bottom: 30px;
    background-color: #D3d3d3;
    box-shadow: -3px 0px 11px 0px rgba(148, 148, 148, 0.75) inset;
    -webkit-box-shadow: -3px 0px 11px 0px rgba(148, 148, 148, 0.75) inset;
    -moz-box-shadow: -3px 0px 11px 0px rgba(148, 148, 148, 0.75) inset;
}

.bottom-nav-signout:hover {
    color: red;
    cursor: pointer;
}

.company-logo-container {
    min-height: 100px;
}

.company-logo {
    width: auto;
    position: fixed;
    top: 7%;
    z-index: 800;
    left: 5%;
}

#companyLogo {
    display: block;
    max-width: 230px;
    max-height: 95px;
    width: auto;
    height: auto;
    position: relative;
}
.company-information{
    position: relative;
}

@media (max-width: 1700px) {
    .side-bar-link {
        font-size: 14px;
    }
}

@media (max-width: 1550px) {
    .side-bar-link {
        font-size: 12px;
    }
}

@media (max-width: 1300px) {
    .side-bar-link {
        font-size: 10px;
    }
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

@media (max-width: 1000px) {
    .side-nav-text {
        visibility: hidden;
    }

    #nav-top-segment {
        font-size: 10px;
        text-align: left;
    }

    .company-logo {
        display: none;
    }

    .company-tin {
        display: none;
    }
}

@media (max-width: 1000px) {
    #nav-top-segment {
        display: none;
    }

    #left-navigation {
        padding-top: 30px;
    }

    .side-nav-link:hover {
        text-decoration: none;
        /* color: transparent; */
    }

}

.box-shadow {
    box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}


.nav-link[data-toggle].collapsed:before {
    content: " ▾";
}

.nav-link[data-toggle]:not(.collapsed):before {
    content: " ▴";
}